<template>
  <div class="page-container">
    <section class="terms">
      <Breadcrumbs />
      <TextContent :content="pageContent.Content" v-inview-animate="{name: 'fade', duration: 500, delay: 300}"></TextContent>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import TextContent from '../../components/TextContent.vue';
import api from '../../tools/api';

export default {
  name: 'TermsAndConditions',
  components: {
    Breadcrumbs,
    TextContent,
  },
  data() {
    return {
      pageContent: {},
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('terms-and-conditions', false);
      this.contentLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
  },
  created() {
    this.getPageContent();
  },
  watch: {
    lang() {
      this.getPageContent();
    },
  },
};
</script>

<style lang="scss" scoped>
:v-deep(.breadcrumbs) {
  @include wRule(
    margin-bottom,
    (
      xl: 78,
      lg: 40,
      sm: 40,
    )
  );
}

.terms {
  position: relative;

  @include wRule(padding-top, (sm: 150));
  @include wRule(padding-bottom, (xl: 100, lg: 70, sm: 150));
}
</style>
